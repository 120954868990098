/* Create two equal columns that floats next to each other */
:root {
  --bg-contrast-color: #04fffc;
  --bg-secondary: #080f1b;
  --bg-secondary-highlight: #222935;
  --bg-primary: #fff;
  --bg-primary-highlight: #fff;
  --small-font-size: 16px;
  --main-font-size: 18px;
  --large-font-size: 24px;
  --largest-font-size: 34px;
  --main-font: "Open Sans";
  --secondary-font: "Open Sans";
  --text-color-primary: #080f1b;
  --text-color-secondary: #fbf8f1;
}
* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
}

footer {
  height: 18.4vh;
  padding: 4vh;
  background: #fff;
  background: var(--bg-primary);
}

h1,
h2,
h3 {
  font-family: "Open Sans";
  font-family: var(--secondary-font);
}
p {
  font-family: "Open Sans";
  font-family: var(--main-font);
  margin-left: 10px;
  font-size: 18px;
  font-size: var(--main-font-size);
}
li {
  font-family: "Open Sans";
  font-family: var(--main-font);
  font-size: 120%;
}
hr {
  border: 1px solid #080f1b;
  border: 1px solid var(--bg-secondary);
}
/* Css to hide google logo and text*/
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
/*end css to hide google logo*/
.logo-container {
  float: left;
  width: 190px;
  height: 95px;
}

.logo {
  height: 100%;
}

.header {
  padding-top: 1vh;
  padding-bottom: 1vh;
  background: #fff;
  background: var(--bg-primary);
  height: 5vh;
}
#menuhead {
  margin-top: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  background: #fff;
  background: var(--bg-primary);
  height: 106px;
}
#flavourtext {
  float: left;
  width: 200px;
  margin-left: 25px;
  margin-right: 10px;
  padding-top: 25px;
  font-size: 16px;
  font-size: var(--small-font-size);
}
.gmap {
  width: 100% !important;
  height: 100vh !important;
  position: relative !important;
}
.button-panel {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
}

.menu {
  position: absolute;
  /* border-radius: 4px; */
  top: 0vh;
  z-index: 1;
  margin-top: 5px;
}
.menu {
  left: 5px;
  width: 450px;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  background: var(--bg-primary);
  color: #080f1b;
  color: var(--text-color-primary);
  /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1); */
}
.menuitem,
.menuaddon {
  /* display: flex; */
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

.menuaddon {
  /*background-color:var(--bg-contrast-color);*/
  color: #fbf8f1;
  color: var(--text-color-secondary);
}
.modal .databox {
  width: 40%;
  margin-left: 4%;
  margin-top: 2%;
  margin-bottom: 2%;
}
.leftcolumn .databox {
  width: 100%;
  margin-left: 15%;
  margin-top: 20%;
  margin-bottom: 25%;
  display: block;
}

.databox,
.databoxleft,
.databoxright {
  margin: 0px;
  display: inline-block;
}
.databoxright {
  width: 35%;
}
.databoxleft {
  width: 65%;
}

.largenumber {
  font-size: 34px;
  font-size: var(--largest-font-size);
  line-height: 34px;
  line-height: var(--largest-font-size);
  font-weight: bold;
  margin-top: 2px; /* between paragraphs */
  margin-bottom: 2px; /* between paragraphs */
  margin-left: 0;
  margin-right: 0;
  color: #080f1b;
  color: var(--text-color-primary);
}
.leftcolumn .largenumber {
  font-weight: 200;
}
.numberheading {
  font-size: 16px;
  font-size: var(--small-font-size);
  line-height: 16px;
  line-height: var(--small-font-size); /* within paragraph */
  margin-top: 4px; /* between paragraphs */
  margin-bottom: 2px; /* between paragraphs */
  margin-left: 3px;
  margin-right: 0;
  color: #080f1b;
  color: var(--text-color-primary);
}
.search-container {
  /*background-color: var(--bg-contrast-color);*/
  position: relative;
  height: 50px;
  border-style: solid;
  border-width: thin;
  border-color: #04fffc;
  border-color: var(--bg-contrast-color);
}

.search-box {
  outline: none;
  border: 0;
  width: 90%;
  height: 100%;
  /*background-color: var(--bg-contrast-color);*/
  font-size: 16px;
  font-size: var(--small-font-size);
  position: absolute;
}
#search-button {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAn1JREFUeJzt2E2ITlEcx/HPyNvCLExJEhEpLyuh8bKzsfC+kJcpWwtlITslZcEKO0myVmKjTIyUhZcdJeVtI2TSCGHMeFtco2fOczVjzrnPM8P51ln8u53f/f3/995z/+eQyWQymUwmk8lkMplM5n+jpWL9ldiIVViAtl/3fIcnuIvL6MK3ir00jBbswgP8GOZ4jn2Y0AS/SZmDm4afeDgeYGmjTadiDXqMPPmB0YvtDfYezWp8VJ9MP85jG+ZiIsZjJjbgzB/mfcOOhmYQwSy8UZ/EZUXSQzFNUYhw/hcsq8Bvcq6pN39wBDod6At0HmFSGpvVsEV98oci9HaW6B2I9Fgpdw02e1V8b3Eq0HytWDtGHUvUL1wLE+hOVTRKtdpbEuiWMi5i7vog7sTDCL0B3uLcEPdKRkwB2oP4UoyRgItBvDKhdjLCVjdlB9caaPepft/y17w02OSMxPofAv3WxPqI+wQaTSVeY0R7gjjlG9CKKTVxP94n1P9NTAEeB/HyGCMBYQv8VPEZJCemALeDeHOMkYDwv38roXYyFhu8SH3HogS6bYrXvVZ7awLdSrhjsNEu8b+r04HmK6O0FYZN6jcvhyP0Okr09kd6rJxO5TvCv30TdqvfDj80ip/+ADPRrb4IVzB/GPOnK3r/suOxo+ntVkO78qOtr7ig2OfPw2TFE52t+GucxaeSebVjbwPziGKV8qOxFGPMFGEWbhh5ovcUyfaWXBszRaA4Bb5v+Ik/wx7FiTGs8w8UAVbgCK7jBT4r1oVuRXd3HGuVd6T/TBFiyEWQi4BcBJQX4WRTHTWB2iKcaLKXprEOx5ptIpPJZDKZTCaTyWQyY5mfYGM5ISWe8pUAAAAASUVORK5CYII=) no-repeat scroll center center / 100% auto rgba(0, 0, 0, 0);
  border: 0;
  color: #080f1b;
  color: var(--text-color-primary);
  width: 40px;
  height: 48px;
  outline: 0;
  left: 90%;
  position: relative;
}

.modal .gmap {
  width: 100% !important;
  height: 50vh !important;
  position: relative !important;
}

.modal-header {
  padding-top: 1vh;
  padding-bottom: 1vh;
  height: 90px;
  background: #fff;
  background: var(--bg-primary);
  color: #080f1b;
  color: var(--text-color-primary);
}

.modal-header .close {
  /*color: var(--text-color-primary);*/
  opacity: 1;
}

.modal-header .close:hover {
  color: #666;
  opacity: 1;
}

.modals {
  max-width: 90% !important;
}

.modal .column {
  width: 42%;
  margin-right: 1.5%;
  margin-left: 3%;
}

.modal-open {
  padding-right: 0px !important;
  overflow-y: scroll;
}

.spinner {
  padding: 30px;
  margin-top: 70px;
  margin-bottom: 70px;
  left: 50%;
  position: relative;
}

.bottom {
  text-align: center;
  padding: 2%;
  background: var(--bg-primary-color); /*var(--bg-contrast-color);*/
}

.blue-band {
  background: #04fffc;
  background: var(--bg-contrast-color);
  color: #080f1b;
  color: var(--text-color-primary);
  padding: 2%;
  width: 100%;
  text-align: center;
}

#analysis-output {
  margin-top: 1vh;
  font-size: 18;
}

.column {
  width: 100%;
}


.leftcolumn,
.rightcolumn,
.column {
  padding: 0vh 1vh 0vh 1vh;
  float: left;
  margin: 0.3vh;
}

.rightcolumn {
  width: 60%;
  padding-left: 15%;
  padding-right: 10%;
}

.leftcolumn {
  width: 30%;
}

.center-column {
  width: 60%;
  margin: auto;
  padding: 2%;
  margin-bottom: 1px;
  text-align: center;
}

.rightcolumn p {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 1%;
  /*box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);*/
  padding: 5%;
}

.rightcolumn h1 {
  background-color: #fff;
  background-color: var(--bg-primary);
  color: #080f1b;
  color: var(--text-color-primary);
  font-size: 22px;
  padding: 10%;
  margin-bottom: 1px;
  margin-top: 0px;
}

.tooltiptext h5 {
  font-size: 18px;
  font-size: var(--main-font-size);
  width: 100%;
}
.tooltiptext p {
  font-size: 16px;
  font-size: var(--small-font-size);
  margin: 0;
}
.tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #080f1b;
  color: var(--text-color-primary);
  text-align: left;
  white-space: nowrap;
  position: relative;
  z-index: 1;
  left: 48px;
  top: -1px;
}
#reset,
#draw,
#help-button {
  width: 50px;
  height: 50px;
}
#reset:hover,
#draw:hover {
  background-size: 42px;
}
#reset {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBUlEQVR4nO2bO3LCMBRFDyFV0uOGLC9MlpTsIJAYaMMeKLMICkoypCIF8owj5J8+lgXvzKjB76F3r2UhBAJBEISwjIAZsAWOwKmhHVXsTOUmTQZsaBZd1TbqPZJkhJv4sglJMsNdfNGee67dC1v+i1jRbjhnKracuw1UY1D0Ca/Ls5xpuUfv1SlCzrInx75c81txF+JNU6LJgCcgBw50n7h0+s4/qNqnDRormQJ7i46H1va2JuQDKN5X+6wSWTexHICHBpNS4Qd4NF2oM8D0HKaMUat8CtRc2/VWRXgqtdQZ8FKXmBA7zloEQRAusPmGNfT1QSdNsg6IXUBsxIDYBcRGDIhdQGzEgB76WHPe5s6AZYv4ZSl+HbAua7puR01KuffAoiZ2rmIKJhb9BcfFADgLnBvidPFwJQbkXArTTZgDY0OMzcZscGx2ZRdUm/COWfyHZV/Bsd2aNg3xMWbxdfNEsgZUDfUyVfPD1RhwwjzkUa+5iu9swNAWQr3cQVdC3P0CH6MgOD6f/5uZBG/6Y/DmF0KmpbBpiF/tUlj/MlQ3tPVH5SoMWCkhE9oN6bwUr/9dzrsB8rtAqCpSQQyIXUBsxIDYBcTGxoBf71X4o3NtNgZ8W+T0RefabAx4s8jpi9c+OvF1FMZ3+wopWsf1MJTvFuVwVdfjcL5bcbwuyfNEgiAMgz8pogSiR2k5pwAAAABJRU5ErkJggg==) no-repeat scroll center center, #080f1b;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBUlEQVR4nO2bO3LCMBRFDyFV0uOGLC9MlpTsIJAYaMMeKLMICkoypCIF8owj5J8+lgXvzKjB76F3r2UhBAJBEISwjIAZsAWOwKmhHVXsTOUmTQZsaBZd1TbqPZJkhJv4sglJMsNdfNGee67dC1v+i1jRbjhnKracuw1UY1D0Ca/Ls5xpuUfv1SlCzrInx75c81txF+JNU6LJgCcgBw50n7h0+s4/qNqnDRormQJ7i46H1va2JuQDKN5X+6wSWTexHICHBpNS4Qd4NF2oM8D0HKaMUat8CtRc2/VWRXgqtdQZ8FKXmBA7zloEQRAusPmGNfT1QSdNsg6IXUBsxIDYBcRGDIhdQGzEgB76WHPe5s6AZYv4ZSl+HbAua7puR01KuffAoiZ2rmIKJhb9BcfFADgLnBvidPFwJQbkXArTTZgDY0OMzcZscGx2ZRdUm/COWfyHZV/Bsd2aNg3xMWbxdfNEsgZUDfUyVfPD1RhwwjzkUa+5iu9swNAWQr3cQVdC3P0CH6MgOD6f/5uZBG/6Y/DmF0KmpbBpiF/tUlj/MlQ3tPVH5SoMWCkhE9oN6bwUr/9dzrsB8rtAqCpSQQyIXUBsxIDYBcTGxoBf71X4o3NtNgZ8W+T0RefabAx4s8jpi9c+OvF1FMZ3+wopWsf1MJTvFuVwVdfjcL5bcbwuyfNEgiAMgz8pogSiR2k5pwAAAABJRU5ErkJggg==) no-repeat scroll center center, var(--text-color-primary);
  background-size: 34px;
}
#draw {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAA6BJREFUeJzt2s2LHEUch/FPXkwED15yjyEYE989eRDxJeJb4ktcRUSigmhUEEHw4L/gQUSEHPSkBxUUPHjwKip4EYVootGDq+4mrqvGF4ga43qobrq3M5ndrqnumiHzhWbeurp/z9M1XT1dwzTTTHMmZ13uAhLmEZyHQ5nryJJH8R9OYCZzLb2nhF8qlhO4J2tFPaYO/ydmVRLuzVhXL6nD/46rsBnfFu/9i/tyFdd16vC/4sraZ1vwnUrC/b1X13Hq8Ccthy+zFd/X1tnbW3Udp3nCW8KbBg/n52NOJeGBQRtcM+C9Zw22mip78UdEu33YL9Q8j4O4ofjs9WK7JxttLsCH2CSIewivrbSjdyw3nHrZ1Ao7ZJ/qyM9hGzbi3dp2X8XaRrs9woiwhJ9xRXPD64fsdBGfRBS7Uv5puX7zyF+Hw8VnM3gbu1Q94GFB1m68ITD+IvSWT1ezw7IHvNey0C4y6Mg30+wJr+AW/FW8XsTlbXY6LgJWA1+mKaFst4jL2u54HAS0gS+zER+pJETBk19ADDzciONGhCevgBTwP+HSUYrIJeAxcfA3SQhPHgGx8DdbDn9JimL6FjAKfDnULUgET78CYuHr4/wCLk5ZVF8CxhKefgTEwt9qOfxFXRTXtYBY+F0q+B91BE+3Ah4XD/+3HuDpTkAs/G4V/FFcmLiuU9KFgFj42/QMT3oBqeB3JKpnxaQUEAt/uwr+iB7hSScgFfz2EetonRQCYuHvEG6ZtYV/Bp/hrXZlDs6oAmLh7xQHDy8U7T5v0ea0GUVACvh54XZ2m4yFgKdVt6OOWT38HqPBM4KA5n302GzAc7XX5wpXbyvlLmFm5yyh21+LrxLVtKqkErBNNT21WDw+jyeHtKnDzwvwh4es30lSCaiP01eruuKLeGLA+jMC/HqnTnb0mtQCjuBL7FT9V+cl4WZnmbtVMzZzMh35MsOmxtqkFHCgeFzA9Xhf+HrsF+bqfxMmMuvw3ySqISqpBJQ/Ug7U3juqkrAVLwtzd2MDT5qvwFrVkFcXsA7nqIaoNQL8D8YEnjQ9YAvOLp7vFIa/HYKUDY11yxPeWMCTRkB9BDjdX1GOCyfFB40RPOkFHBNADxaP5TIrXCKPXVII+EA42R0STnwTlRQCPk6wjWxJdSE0sZkKyF1A7kwF5C4gd6YCcheQO2e8gGEXQtuFX3KTkGtiGw4TsBlPxW54UjJIwCy+6LuQRPk6dwHTTDPNZOV/w058+m+j8XQAAAAASUVORK5CYII=) no-repeat scroll center center, #080f1b;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAA6BJREFUeJzt2s2LHEUch/FPXkwED15yjyEYE989eRDxJeJb4ktcRUSigmhUEEHw4L/gQUSEHPSkBxUUPHjwKip4EYVootGDq+4mrqvGF4ga43qobrq3M5ndrqnumiHzhWbeurp/z9M1XT1dwzTTTHMmZ13uAhLmEZyHQ5nryJJH8R9OYCZzLb2nhF8qlhO4J2tFPaYO/ydmVRLuzVhXL6nD/46rsBnfFu/9i/tyFdd16vC/4sraZ1vwnUrC/b1X13Hq8Ccthy+zFd/X1tnbW3Udp3nCW8KbBg/n52NOJeGBQRtcM+C9Zw22mip78UdEu33YL9Q8j4O4ofjs9WK7JxttLsCH2CSIewivrbSjdyw3nHrZ1Ao7ZJ/qyM9hGzbi3dp2X8XaRrs9woiwhJ9xRXPD64fsdBGfRBS7Uv5puX7zyF+Hw8VnM3gbu1Q94GFB1m68ITD+IvSWT1ezw7IHvNey0C4y6Mg30+wJr+AW/FW8XsTlbXY6LgJWA1+mKaFst4jL2u54HAS0gS+zER+pJETBk19ADDzciONGhCevgBTwP+HSUYrIJeAxcfA3SQhPHgGx8DdbDn9JimL6FjAKfDnULUgET78CYuHr4/wCLk5ZVF8CxhKefgTEwt9qOfxFXRTXtYBY+F0q+B91BE+3Ah4XD/+3HuDpTkAs/G4V/FFcmLiuU9KFgFj42/QMT3oBqeB3JKpnxaQUEAt/uwr+iB7hSScgFfz2EetonRQCYuHvEG6ZtYV/Bp/hrXZlDs6oAmLh7xQHDy8U7T5v0ea0GUVACvh54XZ2m4yFgKdVt6OOWT38HqPBM4KA5n302GzAc7XX5wpXbyvlLmFm5yyh21+LrxLVtKqkErBNNT21WDw+jyeHtKnDzwvwh4es30lSCaiP01eruuKLeGLA+jMC/HqnTnb0mtQCjuBL7FT9V+cl4WZnmbtVMzZzMh35MsOmxtqkFHCgeFzA9Xhf+HrsF+bqfxMmMuvw3ySqISqpBJQ/Ug7U3juqkrAVLwtzd2MDT5qvwFrVkFcXsA7nqIaoNQL8D8YEnjQ9YAvOLp7vFIa/HYKUDY11yxPeWMCTRkB9BDjdX1GOCyfFB40RPOkFHBNADxaP5TIrXCKPXVII+EA42R0STnwTlRQCPk6wjWxJdSE0sZkKyF1A7kwF5C4gd6YCcheQO2e8gGEXQtuFX3KTkGtiGw4TsBlPxW54UjJIwCy+6LuQRPk6dwHTTDPNZOV/w058+m+j8XQAAAAASUVORK5CYII=) no-repeat scroll center center, var(--text-color-primary);
  background-size: 38px;
}
#help-button {
  background: url(/static/media/help.b6c71017.svg) no-repeat scroll center center, #fff;
}
.user-guide-image {
  margin-bottom: 15px;
}
#analyse-button,
#myloc {
  width: 100%;
  height: 48px;
  padding: 5px;
}
#contact-us {
  padding: 10px;
}
#analyse-button:enabled,
#contact-us:enabled,
#myloc:enabled {
  background-color: #080f1b;
  background-color: var(--bg-secondary);
}
#analyse-button:hover,
#contact-us:not(:disabled):hover,
#myloc:hover {
  background-color: #222935;
  background-color: var(--bg-secondary-highlight);
}

#contact-us:disabled {
  color: #ccc;
}

.button:focus {
  outline: none;
}
.button:active {
  box-shadow: 0 0 2px 0 #666;
  -webkit-transform: translateY(0.5px);
          transform: translateY(0.5px);
}
.button:hover .tooltiptext {
  visibility: visible;
}

.button:not(:disabled):hover {
  background-color: #222935;
  background-color: var(--bg-secondary-highlight);
}

.button {
  transition-duration: 0.1s;
  background-color: #fff;
  background-color: var(--bg-primary);
  width: 100%;
  border: none;
  font-family: "Open Sans";
  font-family: var(--main-font);
  color: #fbf8f1;
  color: var(--text-color-secondary);
  font-size: 24px;
  font-size: var(--large-font-size);
  padding: 10px;
  margin-right: 5px;
  display: block;
}

.markdownButton {
  background-color: transparent;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  font-size: 24px;
  font-size: var(--large-font-size);
}

.cookieButtons {
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 55px;
  margin-right: 70px;
}

.cookieContent {
  margin-right: 40px;
}

.signUpRow {
  margin-left: 4%;
  position: "absolute";
  bottom: "1%";
  left: "50%";
  -webkit-transform: "translate(-50%, -50%)";
          transform: "translate(-50%, -50%)";
  width: 100%;
  padding-left: 25%;
  padding-right: 25%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.form-group {
  margin-right: 5%;
  width: 45%;
}

#start-modal {
  width: 500px;
}

#start-modal .center-column {
  width: 385px;
  margin-bottom: 30px;
}

#start-modal h2 {
  margin-top: 30px;
  margin-bottom: 30px;
}

#start-modal .button,
#start-modal .combobox {
  font-size: 24px;
  font-size: var(--large-font-size);
  text-align: center;
}

#start-modal p {
  margin-top: 10px; /* between paragraphs */
  margin-bottom: 10px; /* between paragraphs */
  font-size: 24px;
  font-size: var(--large-font-size);
}

.combobox {
  font-family: "Open Sans";
  font-family: var(--main-font);
  font-size: 16px;
  font-size: var(--small-font-size);
  color: black;
  border: 0;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  height: 48px;
}
@media screen and (max-width: 1250px) {
  .leftcolumn .databox {
    width: 100%;
    margin-left: 15%;
    margin-top: 18%;
    margin-bottom: 15%;
    display: block;
  }
  .largenumber {
    font-size: 24px;
    font-size: var(--large-font-size);
    line-height: 24px;
    line-height: var(--large-font-size);
    font-weight: bold;
    /* margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 0;
    margin-right: 0; */
  }
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .leftcolumn,
  .rightcolumn,
  .column,
  .center-column {
    width: 100%;
    margin: 0;
    padding: 0vh 1.9vh 0vh 1.9vh;
  }
  body {
    position: relative;
  }
  html {
    overflow-y: hidden;
  }

  .rightcolumn .leftcolumn {
    display: flex;
    flex-direction: column;
  }
  .header {
    padding-top: 1vh;
    padding-bottom: 1vh;
    background: #fff;
    background: var(--bg-primary);
    height: 15vh;
  }

  .modal-header {
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    height: 9vh;
    background: #fff;
    background: var(--bg-primary);
    color: #080f1b;
    color: var(--text-color-primary);
  }
  footer {
    height: 25vh;
    /*padding: 2vh;*/
    background: #fff;
    background: var(--bg-primary);
    overflow: hidden;
  }

  .modal .column {
    width: 100%;
  }

  .rightcolumn h1 {
    order: 2;
  }

  .rightcolumn p {
    order: 3;
    padding: 0;
    margin: 0;
  }

  .signUpColumn {
    margin-left: 4%;
  }

  .form-group {
    margin-right: 4%;
    width: 100%;
  }
}

